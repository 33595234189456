import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import Footer from "components/Footers/Footer.js";

function WorkPage() {
    React.useEffect(() => {
        document.body.classList.add("signup-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("signup-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);
    return (
        <>
            <ScrollTransparentNavbar type="work" />
            <div className="page-header header-filter" filter-color="black">
                <div
                    className="page-header-image"
                    style={{
                        backgroundImage:
                            "url(" + require("assets/img/pricing3.webp").default + ")",
                    }}
                />
                <div className="content-center">
                    <Row>
                        <Col className="ml-auto mr-auto" md="8">
                            <h1 className="title">Our Work</h1>
                            <h4>
                                We build next generation digital products for visionary companies
                            </h4>
                            <div class="arrow">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Col>
                    </Row>
                </div>

            </div>
            <div className="cd-section" id="projects">
                <div className="projects-1" style={{padding:0}}>
                    <Container fluid>
                        <Row>
                            <Col className="px-0" md="6">
                                <Card
                                    className="card-raised card-background card-background-product card-no-shadow"
                                    style={{
                                        backgroundImage:
                                            "url(" +
                                            require("assets/img/pangolin.png").default +
                                            ")",
                                    }}
                                >
                                    <CardBody>
                                        <h6 className="category">Web3 Application</h6>
                                        <CardTitle tag="h3">Pangolin</CardTitle>
                                        <p className="card-description">
                                            Scale your DeFi trading. We contribute to Pangolin Exchange by building a new Bridge System and more...
                                        </p>
                                        <Button
                                            className="btn-round"
                                            color="danger"
                                            href="https://app.pangolin.exchange"
                                            // onClick={(e) => e.preventDefault()}
                                        >
                                            Visit
                                        </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="px-0" md="6">
                                <Card
                                    className="card-background card-background-product card-no-shadow"
                                    style={{
                                        backgroundImage:
                                            "url(" + require("assets/img/audiox-project.jpeg").default + ")",
                                    }}
                                >
                                    <CardBody>
                                        <h6 className="category">Web Application</h6>
                                        <CardTitle tag="h3">AudioX</CardTitle>
                                        <p className="card-description">
                                        The platform where audio data is managed, and edited with auto-split, auto-crop and other audio operations.
                                        </p>
                                        {/* <Button
                                            className="btn-round"
                                            color="danger"
                                            href="#pablo"
                                            onClick={(e) => e.preventDefault()}
                                        >
                                            <i className="now-ui-icons ui-1_send"></i> View Book
                                        </Button> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="px-0" md="6">
                                <Card
                                    className="card-raised card-background card-background-product card-no-shadow"
                                    style={{
                                        backgroundImage:
                                            "url(" +
                                            require("assets/img/nft-marketplace.jpeg").default +
                                            ")",
                                    }}
                                >
                                    <CardBody>
                                        <h6 className="category">Web3 Application</h6>
                                        <CardTitle tag="h3">NFT Marketplace</CardTitle>
                                        <p className="card-description">
                                            Decentralized marketplace for NFTs built on Solana. You can mint your own NFTs and sell them on the marketplace.
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="px-0" md="6">
                                <Card
                                    className="card-raised card-background card-background-product card-no-shadow"
                                    style={{
                                        backgroundImage:
                                            "url(" +
                                            require("assets/img/pbuilder-project.webp").default +
                                            ")",
                                    }}
                                >
                                    <CardBody>
                                        <h6 className="category">Website</h6>
                                        <CardTitle tag="h3">Peacetrain</CardTitle>
                                        <p className="card-description">
                                        Peacetrain website with a milliseconds boot time, which we developed using modern web technologies.
                                        </p>
                                        <Button
                                            className="btn-round"
                                            color="danger"
                                            href="https://peacetrain.catstevens.com"
                                            // onClick={(e) => e.preventDefault()}
                                        >
                                            Visit
                                        </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="px-0" md="6">
                                <Card
                                    className="card-background card-background-product card-no-shadow"
                                    style={{
                                        backgroundImage:
                                            "url(" +
                                            require("assets/img/catstevens.jpeg").default +
                                            ")",
                                    }}
                                >
                                    <CardBody>
                                        <h6 className="category">Website</h6>
                                        <CardTitle tag="h3">Catstevens</CardTitle>
                                        <p className="card-description">
                                            We maintain and develop the website of famous singer Catstevens.
                                        </p>
                                        <Button
                                            className="btn-round"
                                            color="danger"
                                            href="https://catstevens.com/think"
                                        >
                                            Visit
                                        </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="px-0" md="6">
                                <Card
                                    className="card-background card-background-product card-no-shadow"
                                    style={{
                                        backgroundImage:
                                            "url(" +
                                            require("assets/img/dca-app.jpeg").default +
                                            ")",
                                    }}
                                >
                                    <CardBody>
                                        <h6 className="category">Mobile Application</h6>
                                        <CardTitle tag="h3">Diyanet Center Of America</CardTitle>
                                        <p className="card-description">
                                            The app includes the Diyanet mosques in the USA, and the mosque's events, 
                                            announcements and prayer times.
                                        </p>
                                        <Button
                                            className="btn-round"
                                            color="danger"
                                            href="https://apps.apple.com/gb/app/diyanet-center-of-america/id1469177966"
                                        >
                                            App Store
                                        </Button>
                                        <Button
                                            className="btn-round"
                                            color="danger"
                                            href="https://play.google.com/store/apps/details?id=com.diyanetcenterofamerica.app"
                                        >
                                            Play Store
                                        </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="px-0" md="6">
                                <Card
                                    className="card-background card-background-product card-no-shadow"
                                    style={{
                                        backgroundImage:
                                            "url(" +
                                            require("assets/img/recordx-project.jpeg").default +
                                            ")",
                                    }}
                                >
                                    <CardBody>
                                        <h6 className="category">Web Application</h6>
                                        <CardTitle tag="h3">RecordX</CardTitle>
                                        <p className="card-description">
                                            A web application where voice actors can record and store sound data.
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="px-0" md="6">
                                <Card
                                    className="card-background card-background-product card-no-shadow"
                                    style={{
                                        backgroundImage:
                                            "url(" +
                                            require("assets/img/happyearthsolutions-project.webp").default +
                                            ")",
                                    }}
                                >
                                    <CardBody>
                                        <h6 className="category">Website</h6>
                                        <CardTitle tag="h3">Happy Earth Solutions</CardTitle>
                                        <p className="card-description">
                                            Macro website developed with Wordpress technology.
                                        </p>
                                        <Button
                                            className="btn-round"
                                            color="danger"
                                            href="https://www.happyearthsolutions.com"
                                        >
                                            Visit
                                        </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="px-0" md="6">
                                <Card
                                    className="card-background card-background-product card-no-shadow"
                                    style={{
                                        backgroundImage:
                                            "url(" +
                                            require("assets/img/ldb-project.jpeg").default +
                                            ")",
                                    }}
                                >
                                    <CardBody>
                                        <h6 className="category">Web Application</h6>
                                        <CardTitle tag="h3">Language Database Tool</CardTitle>
                                        <p className="card-description">
                                            The only platform where you can manage and
                                            track the multilanguage support of your data.
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="px-0" md="6">
                                <Card
                                    className="card-background card-background-product card-no-shadow"
                                    style={{
                                        backgroundImage:
                                            "url(" +
                                            require("assets/img/shuratrust-project.jpeg").default +
                                            ")",
                                    }}
                                >
                                    <CardBody>
                                        <h6 className="category">Website</h6>
                                        <CardTitle tag="h3">Shura Trust</CardTitle>
                                        <p className="card-description">
                                            The Shura trust official website is made with the WordPress tech stack.
                                        </p>
                                        <Button
                                            className="btn-round"
                                            color="danger"
                                            href="https://shuratrust.org/"
                                        >
                                            Visit
                                        </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="px-0" md="6">
                                <Card
                                    className="card-background card-background-product card-no-shadow"
                                    style={{
                                        backgroundImage:
                                            "url(" +
                                            require("assets/img/aifa-mobile-app.jpeg").default +
                                            ")",
                                    }}
                                >
                                    <CardBody>
                                        <h6 className="category">Mobile Application</h6>
                                        <CardTitle tag="h3">AIFA</CardTitle>
                                        <p className="card-description">
                                            AIFA is a bespoke educational digital streaming audio(karaoke) 
                                            product, specifically tailored towards providing 
                                            educational material for young children.
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="px-0" md="6">
                                <Card
                                    className="card-background card-background-product card-no-shadow"
                                    style={{
                                        backgroundImage:
                                            "url(" +
                                            require("assets/img/anp-project.jpeg").default +
                                            ")",
                                    }}
                                >
                                    <CardBody>
                                        <h6 className="category">Web Application</h6>
                                        <CardTitle tag="h3">ANP Appointment System</CardTitle>
                                        <p className="card-description">
                                            It is a web application that brings users and 
                                            therapists together and allows them to make 
                                            appointments through an calendar.
                                        </p>
                                        <Button
                                            className="btn-round"
                                            color="danger"
                                            href="https://kayit.anpsikoloji.com"
                                        >
                                            Visit
                                        </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default WorkPage;
