import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function Content() {
  return (
    <>
      <div className="section section-content" data-background-color="black">
        <Container>
          <Row>
            <Col md="5">
              <div className="image-container">
                <img
                  alt="..."
                  className="img img-raised rounded img-comments rellax"
                  data-rellax-speed="1"
                  src={
                    require("assets/img/presentation-page/content1.jpg").default
                  }
                ></img>
                <img
                  alt="..."
                  className="img img-raised rounded img-blog rellax"
                  data-rellax-speed="4"
                  src={
                    require("assets/img/presentation-page/content4.jpg").default
                  }
                ></img>
              </div>
            </Col>
            <Col className="ml-auto mr-auto" md="4">
              <div className="section-description">
                <h2 className="title">Amazing Works</h2>
                <h6 className="category">We design your future</h6>
                <h5 className="description">
                  We offer professional services to our customers in numerous fields and 
                  use the most up-to-date technologies in our projects.
                </h5>
                <Button
                  className="btn-round mr-1"
                  to="/our-work"
                  // target="_blank"
                  color="default"
                  tag={Link}
                  size="lg"
                >
                  Our Work
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Content;
