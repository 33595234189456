import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function Image() {
  return (
    <>
      <div
        className="section features-7 section-image"
        style={{
          backgroundImage:
            "url(" + require("assets/img/bg51.webp").default + ")",
        }}
      >
        <Container fluid>
          <Row>
            <Col className="px-0" md="6">
              <Col sm="12">
                <div className="info info-horizontal">
                  <div className="icon">
                    <i className="now-ui-icons business_globe"></i>
                  </div>
                  <div className="description">
                    <h5 className="info-title">Web Development</h5>
                    <p className="description">
                      We develop websites and web applications with regard to your needs 
                      in the highest quality and a rapid way. We also integrate other 
                      solutions into your web application need-based. 
                      These are artificial intelligence, image and sound processing ...
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon">
                    <i className="now-ui-icons tech_mobile"></i>
                  </div>
                  <div className="description">
                    <h5 className="info-title">Application Development</h5>
                    <p className="description">
                      We design and enhance your mobile applications in proportion to your necessities quickly. 
                      Furthermore, we remember the content management panel and API!
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon">
                    <i className="now-ui-icons tech_headphones"></i>
                  </div>
                  <div className="description">
                    <h5 className="info-title">Audio & Image Processing</h5>
                    <p className="description">
                      We make complex structures easy. We develop the image and 
                      audio processing solutions required in your applications.
                    </p>
                  </div>
                </div>
              </Col>
            </Col>
            <Col md="6">
              <div className="image-container">
                <img
                  alt="..."
                  src={require("assets/img/ipad3.webp").default}
                ></img>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Image;
