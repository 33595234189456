import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  NavLink
} from "reactstrap";

function DropdownWhiteNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="fixed-top" color="white" expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand to="/" tag={Link} style={{ fontFamily: "AvantGardeGothicRegular", fontSize: '1.25rem',display:"flex", alignItems:"center" }}>
            <img src={require("assets/img/redsceptre-icon.png").default} alt="logo" height={48} width={48} />
            &nbsp; <span className="redText">RED</span>SCEPTRE
          </NavbarBrand>
          <button
              aria-expanded={collapseOpen}
              className="navbar-toggler"
              data-toggle="collapse"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
        </div>
        <Collapse isOpen={collapseOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink to="/" tag={Link}>
                Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/services" tag={Link}>
                Services
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/our-work" tag={Link}>
                Work
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/about-us" tag={Link}>
                About Us
              </NavLink>
            </NavItem>
            <NavItem className="active">
              <NavLink to="/contact-us" tag={Link}>
                Contact
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
      </Navbar>
    </>
  );
}

export default DropdownWhiteNavbar;
