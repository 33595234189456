/*eslint-disable*/
import React from "react";

// reactstrap components

// core components

function PresentationHeader() {
  return (
    <>
      <div className="page-header clear-filter">
        <div className="rellax-header rellax-header-sky" data-rellax-speed="-4">
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" +
                require("assets/img/top-bc.webp")
                  .default +
                ")",
            }}
          ></div>
        </div>
        <div
          className="rellax-header rellax-header-buildings"
          data-rellax-speed="0"
        >
          <div
            className="page-header-image page-header-city"
            style={{
              backgroundImage:
                "url(" +
                require("assets/img/london-bottom-min-1.webp")
                  .default +
                ")",
            }}
          ></div>
        </div>
        <div className="rellax-text-container rellax-text">
          <h1 className="h1-seo" data-rellax-speed="-1">
            REDSCEPTRE
          </h1>
          {/* <div className="pro">IT</div> */}
        </div>
        <h2 className="h3-description rellax-text" data-rellax-speed="-1">
            We design your future.
        </h2>
        <h6
          className="category category-absolute rellax-text"
          data-rellax-speed="-1"
        >
        </h6>
      </div>
    </>
  );
}

export default PresentationHeader;
