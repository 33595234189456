import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  NavItem,
  NavLink,
  Nav,
  Badge,
  TabContent,
  TabPane,
} from "reactstrap";

// core components
import ScrollTransparentShortNavbar from "components/Navbars/ScrollTransparentShortNavbar.js";
import ServicesHeader from "components/Headers/ServicesHeader.js";
import Footer from "components/Footers/Footer.js";

function Services() {
  const [pills, setPills] = React.useState("1");
  const [firstNameFocus, setFirstNameFocus] = React.useState(false);
  const [lastNameFocus, setLastNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ScrollTransparentShortNavbar type="services" />
      <div className="wrapper">
        <ServicesHeader />
        <div className="main">
          <div className="pricing-4" style={{paddingBottom:0}}>
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="6">
                  <h4 className="description">
                  We offer a range of services to help businesses build their products
                  including Robotic Process Automation (RPA), 
                  Audio&Image Processing, Website, Webapp, Mobile Application, Voice Activity Detection, 
                  Server Management, Cloud Solutions and more.
                  </h4>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="section">
            <Container>
              <Row>
                <Col md="12">
                  <h2 className="title text-center" style={{paddingBottom: 30}}>Services</h2>
                  <div className="nav-align-center">
                    <Nav
                      className="nav-pills-primary nav-pills-icons"
                      pills
                      role="tablist"
                      style={{
                        justifyContent:'space-around',
                      }}
                    >
                      <NavItem style={{
                        paddingBottom: 5
                      }}>
                        <NavLink
                          onClick={(e) => {
                            e.preventDefault();
                            setPills("1");
                          }}
                          className={pills === "1" ? "active" : ""}
                          role="tablist"
                          style={{cursor:'pointer'}}
                        >
                          <i className="now-ui-icons business_globe"></i>
                          Website&Web App
                        </NavLink>
                      </NavItem>
                      <NavItem style={{
                        paddingBottom: 5
                      }}>
                        <NavLink
                          onClick={(e) => {
                            e.preventDefault();
                            setPills("2");
                          }}
                          className={pills === "2" ? "active" : ""}
                          role="tablist"
                          style={{cursor:'pointer'}}
                          >
                          <i className="now-ui-icons tech_mobile"></i>
                          Mobile Application
                        </NavLink>
                      </NavItem>
                      <NavItem style={{
                        paddingBottom: 5
                      }}>
                        <NavLink
                          onClick={(e) => {
                            e.preventDefault();
                            setPills("4");
                          }}
                          className={pills === "4" ? "active" : ""}
                          role="tablist"
                          style={{cursor:'pointer'}}
                        >
                          <i className="now-ui-icons objects_spaceship"></i>
                          Robotic Process
                        </NavLink>
                      </NavItem>
                      <NavItem style={{
                        paddingBottom: 5
                      }}>
                        <NavLink
                          onClick={(e) => {
                            e.preventDefault();
                            setPills("3");
                          }}
                          className={pills === "3" ? "active" : ""}
                          role="tablist"
                          style={{cursor:'pointer'}}
                        >
                          <i className="now-ui-icons objects_diamond"></i>
                          Niche Services
                        </NavLink>
                      </NavItem>
                      <NavItem style={{
                        paddingBottom: 5
                      }}>
                        <NavLink
                          onClick={(e) => {
                            e.preventDefault();
                            setPills("5");
                          }}
                          className={pills === "5" ? "active" : ""}
                          role="tablist"
                          style={{cursor:'pointer'}}
                        >
                          <i className="now-ui-icons design_vector"></i>
                          Blockchain
                        </NavLink>
                      </NavItem>
                      <NavItem style={{
                        paddingBottom: 5
                      }}>
                        <NavLink
                          onClick={(e) => {
                            e.preventDefault();
                            setPills("6");
                          }}
                          className={pills === "6" ? "active" : ""}
                          role="tablist"
                          style={{cursor:'pointer'}}
                        >
                          <i className="now-ui-icons arrows-1_cloud-upload-94"></i>
                          Cloud Solutions
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                  <TabContent className="gallery" activeTab={"pills" + pills}>
                    <TabPane tabId="pills1">
                      <Row>
                        <Col className="ml-auto mr-auto" md="5">
                          <Card
                            className="card-background card-background-product card-raised"
                            style={{
                              backgroundImage:
                                "url(" +
                                require("assets/img/website-service.jpeg").default +
                                ")",
                            }}
                          >
                            <CardBody>
                              <CardTitle tag="h2">Website & Web App</CardTitle>
                              <p className="card-description text-white">
                              Redsceptre can help you create fully-fledged web apps and websites. We have a hands-on experience 
                              with database integrations and APIs to make your projects scalable and responsive. 
                              The hi-end technologies speed up development without damaging your product quality.
                              </p>
                              <div class="arrow">
                                <span></span>
                                <span></span>
                                <span></span>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md="5">
                          <div className="info info-horizontal">
                            <div className="icon icon-danger">
                              <i className="now-ui-icons business_bulb-63"></i>
                            </div>
                            <div className="description">
                              <h5 className="info-title">Full Cycle Development</h5>
                              <p className="description">
                                Redsceptre manages each software development stage so that you can devote 
                                more time to business.
                              </p>
                            </div>
                          </div>
                          <div className="info info-horizontal">
                            <div className="icon icon-danger">
                              <i className="now-ui-icons business_bulb-63"></i>
                            </div>
                            <div className="description">
                              <h5 className="info-title">
                              Complex Web Development
                              </h5>
                              <p className="description">
                              With a wide range of top-notch technologies at our fingertips, we are ready 
                              to solve all your business needs regardless of their complexity and uniqueness. 
                              You describe your business task and we provide the best solution for the web.
                              </p>
                            </div>
                          </div>
                          <div className="info info-horizontal">
                            <div className="icon icon-danger">
                              <i className="now-ui-icons business_bulb-63"></i>
                            </div>
                            <div className="description">
                              <h5 className="info-title">
                                Custom website
                              </h5>
                              <p className="description">
                                Your website/app will be built to reflect your needs on the basis of an in-depth analysis of your business goals. 
                                The website/app will be developed so it can easily scale up, keeping in line with your business growth.
                              </p>
                            </div>
                          </div>
                          <div className="info info-horizontal">
                            <div className="icon icon-danger">
                              <i className="now-ui-icons business_bulb-63"></i>
                            </div>
                            <div className="description">
                              <h5 className="info-title">
                                Support and maintenance
                              </h5>
                              <p className="description">
                                Ongoing support and maintenance of your website according to the service agreements.
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="pills2">
                      <Row>
                        <Col className="ml-auto mr-auto" md="5">
                          <Card
                            className="card-background card-background-product card-raised"
                            style={{
                              backgroundImage:
                                "url(" +
                                require("assets/img/mobile-service.jpeg").default +
                                ")",
                            }}
                          >
                            <CardBody>
                              <CardTitle tag="h2">Mobile Application</CardTitle>
                              <p className="card-description text-white">
                                Developing software for Android, iOS and SmartWatch, Redsceptre unleashes the platforms’ 
                                maximum potential to build robust and user-friendly apps. We provide a full scope of 
                                mobile development services including apps submission for the review.
                              </p>
                              <div class="arrow">
                                <span></span>
                                <span></span>
                                <span></span>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md="5">
                        <div className="info info-horizontal">
                            <div className="icon icon-danger">
                              <i className="now-ui-icons business_bulb-63"></i>
                            </div>
                            <div className="description">
                              <h5 className="info-title">High Performance</h5>
                              <p className="description">
                              We build custom-made mobile applications using the most optimal technology stack to ensure their quality.
                              </p>
                            </div>
                          </div>
                          <div className="info info-horizontal">
                            <div className="icon icon-danger">
                              <i className="now-ui-icons business_bulb-63"></i>
                            </div>
                            <div className="description">
                              <h5 className="info-title">Better Cost Efficiency</h5>
                              <p className="description">
                              Each iteration review helps identify bugs long before release date, preventing unexpected costs, and with smart team extensions, 
                              you spend less time hiring and onboarding new personnel.
                              </p>
                            </div>
                          </div>
                          <div className="info info-horizontal">
                            <div className="icon icon-danger">
                              <i className="now-ui-icons business_bulb-63"></i>
                            </div>
                            <div className="description">
                              <h5 className="info-title">Enhanced Product Quality</h5>
                              <p className="description">
                                Our extensive hands-on experience with handling technical challenges ensures the integrity of your product.
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="pills3">
                      <Row>
                        <Col className="ml-auto mr-auto" md="5">
                          <Card
                            className="card-background card-background-product card-raised"
                            style={{
                              backgroundImage:
                                "url(" +
                                require("assets/img/niche-service.jpeg").default +
                                ")",
                            }}
                          >
                            <CardBody>
                              <CardTitle tag="h2">Niche Services</CardTitle>
                              <p className="card-description text-white">
                              Redsceptre is your conduit to cutting-edge technological innovations and technical 
                              research in the areas of AI, Machine Learning, Audio&Image Processing, Voice Activity Detection, Computer Vision and advanced media analysis & processing, anomaly detection, fraud detection, 
                              fault detection, system health monitoring, and recommender systems.
                              </p>
                              <div class="arrow">
                                <span></span>
                                <span></span>
                                <span></span>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md="5">
                          <div className="info info-horizontal">
                            <div className="icon icon-danger">
                              <i className="now-ui-icons business_bulb-63"></i>
                            </div>
                            <div className="description">
                              <h5 className="info-title">Proven mathematical methods</h5>
                              <p className="description">
                               With Redsceptre you get a professional solution based on the best mathematical methods and numerical computation algorithms.
                              </p>
                            </div>
                          </div>
                          <div className="info info-horizontal">
                            <div className="icon icon-danger">
                              <i className="now-ui-icons business_bulb-63"></i>
                            </div>
                            <div className="description">
                              <h5 className="info-title">End-to-end solutions</h5>
                              <p className="description">
                                Redsceptre extends existing solutions and develops and 
                                implements experimental projects from prototype to working systems.
                              </p>
                            </div>
                          </div>
                          <div className="info info-horizontal">
                            <div className="icon icon-danger">
                              <i className="now-ui-icons business_bulb-63"></i>
                            </div>
                            <div className="description">
                              <h5 className="info-title">On premises deployment</h5>
                              <p className="description">
                                We will deploy, adapt, and support suggested solutions of ML and AI within the customer’s infrastructure.
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="pills4">
                      <Row>
                        <Col className="ml-auto mr-auto" md="5">
                          <Card
                            className="card-background card-background-product card-raised"
                            style={{
                              backgroundImage:
                                "url(" +
                                require("assets/img/rpa-service.jpeg").default +
                                ")",
                            }}
                          >
                            <CardBody>
                              <CardTitle tag="h2">Robotic Process Automation</CardTitle>
                              <p className="card-description text-white">
                                RPA can help enterprises improve the efficiency of their operations faster and at a 
                                lower cost than other automation approaches. It equips your organisation with tools to create 
                                its own software robots to automate entire end-to-end business processes.
                              </p>
                              <div class="arrow">
                                <span></span>
                                <span></span>
                                <span></span>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md="5">
                          <div className="info info-horizontal">
                            <div className="icon icon-danger">
                              <i className="now-ui-icons business_bulb-63"></i>
                            </div>
                            <div className="description">
                              <h5 className="info-title">Cloud Integration</h5>
                              <p className="description">
                              Enable seamless access to operational data and 
                              various business applications using one virtual environment.
                              </p>
                            </div>
                          </div>
                          <div className="info info-horizontal">
                            <div className="icon icon-danger">
                              <i className="now-ui-icons business_bulb-63"></i>
                            </div>
                            <div className="description">
                              <h5 className="info-title">Process Time-Efficiency</h5>
                              <p className="description">
                              Accelerate business processes with the help of time-cutting automation practices.
                              </p>
                            </div>
                          </div>
                          <div className="info info-horizontal">
                            <div className="icon icon-danger">
                              <i className="now-ui-icons business_bulb-63"></i>
                            </div>
                            <div className="description">
                              <h5 className="info-title">Operational Speed Assessment</h5>
                              <p className="description">
                                Attain insights into the current speed and scale of business operations as a basis for automation.
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="pills5">
                      <Row>
                        <Col className="ml-auto mr-auto" md="5">
                          <Card
                            className="card-background card-background-product card-raised"
                            style={{
                              backgroundImage:
                                "url(" +
                                require("assets/img/blockchain-service.jpeg").default +
                                ")",
                            }}
                          >
                            <CardBody>
                              <CardTitle tag="h2">Blockchain</CardTitle>
                              <p className="card-description text-white">
                                Blockchain is changing the way the world lives and works – 
                                but it's only a means to an end, not the end itself. 
                                We can help you apply this technology to drive your transformation into the future.
                              </p>
                              <div class="arrow">
                                <span></span>
                                <span></span>
                                <span></span>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md="5">
                          <div className="info info-horizontal">
                            <div className="icon icon-danger">
                              <i className="now-ui-icons business_bulb-63"></i>
                            </div>
                            <div className="description">
                              <h5 className="info-title">Distributed ledger and peer-to-peer network</h5>
                              <p className="description">
                                Blocks of data are chained together, and that blockchain is replicated on different nodes of a network, 
                                ensuring resilience and transparency.
                              </p>
                            </div>
                          </div>
                          <div className="info info-horizontal">
                            <div className="icon icon-danger">
                              <i className="now-ui-icons business_bulb-63"></i>
                            </div>
                            <div className="description">
                              <h5 className="info-title">Digital signatures and hash functions</h5>
                              <p className="description">
                                Strong encryption and antitampering measures mean that data can't be retroactively 
                                alteredand that fosters trust.
                              </p>
                            </div>
                          </div>
                          <div className="info info-horizontal">
                            <div className="icon icon-danger">
                              <i className="now-ui-icons business_bulb-63"></i>
                            </div>
                            <div className="description">
                              <h5 className="info-title">Consensus algorithms</h5>
                              <p className="description">
                              A consensus mechanism authenticates the underlying data, 
                              eliminating the need for intermediaries (such as banks and brokers) in a transaction.
                              </p>
                            </div>
                          </div>
                          <div className="info info-horizontal">
                            <div className="icon icon-danger">
                              <i className="now-ui-icons business_bulb-63"></i>
                            </div>
                            <div className="description">
                              <h5 className="info-title">Smart contracts</h5>
                              <p className="description">
                                Under certain circumstances, software code is triggered to execute contractual 
                                obligations (such as an asset transfer) automatically.
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="pills6">
                      <Row>
                        <Col className="ml-auto mr-auto" md="5">
                          <Card
                            className="card-background card-background-product card-raised"
                            style={{
                              backgroundImage:
                                "url(" +
                                require("assets/img/cloud-service.jpeg").default +
                                ")",
                            }}
                          >
                            <CardBody>
                              <CardTitle tag="h2">Cloud&Server Solutions</CardTitle>
                              <p className="card-description text-white">
                                Redsceptre has the experience and expertise to help your business unlock 
                                the value of the cloud by lowering your expenses and improving your efficiency.
                              </p>
                              <div class="arrow">
                                <span></span>
                                <span></span>
                                <span></span>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md="5">
                          <div className="info info-horizontal">
                            <div className="icon icon-danger">
                              <i className="now-ui-icons business_bulb-63"></i>
                            </div>
                            <div className="description">
                              <h5 className="info-title">Better Cost Efficiency</h5>
                              <p className="description">
                                Adjusting virtual machine sizes, load balancing/autoscaling rules, 
                                container orchestration configurations, 
                                and database server sizes are a few ways we accomplish savings.
                              </p>
                            </div>
                          </div>
                          <div className="info info-horizontal">
                            <div className="icon icon-danger">
                              <i className="now-ui-icons business_bulb-63"></i>
                            </div>
                            <div className="description">
                              <h5 className="info-title">Secure Your Data</h5>
                              <p className="description">
                              We can leverage our experience applying information security best practices in high compliance environments (e.g. HIPAA, SOC, PCI, etc.) 
                              to help you secure your cloud network and storage.
                              </p>
                            </div>
                          </div>
                          <div className="info info-horizontal">
                            <div className="icon icon-danger">
                              <i className="now-ui-icons business_bulb-63"></i>
                            </div>
                            <div className="description">
                              <h5 className="info-title">Server Management</h5>
                              <p className="description">
                                We provide server consultancy on specific projects, to any size organisation,
                                enhancing the capabilities of your in house team
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </Container>
          </div>
          {/* <div className="section section-frequently">
            <Container>
              <div className="text-center">
                <h3 className="title">Frequently Asked Questions</h3>
              </div>
              <Row>
                <Col className="ml-auto" md="4">
                  <div className="info info-horizontal">
                    <div className="icon icon-danger">
                      <i className="now-ui-icons shopping_credit-card"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">
                        Can I cancel my subscription?
                      </h4>
                      <p>
                        Yes, you can cancel and perform other actions on your
                        subscriptions via the My Account page.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="mr-auto" md="4">
                  <div className="info info-horizontal">
                    <div className="icon icon-success">
                      <i className="now-ui-icons shopping_box"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">
                        Is there any discount for an annual subscription?
                      </h4>
                      <p>
                        Yes, we offer a 40% discount if you choose annual
                        subscription for any plan.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto" md="4">
                  <div className="info info-horizontal">
                    <div className="icon icon-success">
                      <i className="now-ui-icons business_money-coins"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">
                        Which payment methods do you take?
                      </h4>
                      <p>
                        WooCommerce comes bundled with PayPal (for accepting
                        credit card and PayPal account).
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="mr-auto" md="4">
                  <div className="info info-horizontal">
                    <div className="icon icon-danger">
                      <i className="now-ui-icons business_bulb-63"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">
                        Any other questions we can answer?
                      </h4>
                      <p>We are happy to help you. Contact us.</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div> */}
          <div
            className="contactus-1 section-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/bg27.jpg").default + ")",
            }}
          >
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="5">
                  <h2 className="title">Get in Touch</h2>
                  <h4 className="description">
                    You can contact us with anything related to our Services. 
                    We'll get in touch with you as soon as possible.
                  </h4>
                  <div className="info info-horizontal">
                    <div className="icon icon-danger">
                      <i className="now-ui-icons location_pin"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Address</h4>
                      <p className="description">
                        7 Bell Yard, <br></br>
                        WC2A 2JR, London, <br></br>
                        United Kingdom
                      </p>
                    </div>
                  </div>
                  {/* <div className="info info-horizontal">
                    <div className="icon icon-danger">
                      <i className="now-ui-icons tech_mobile"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Give us a ring</h4>
                      <p className="description">
                        Michael Jordan <br></br>
                        +40 762 321 762 <br></br>
                        Mon - Fri, 8:00-22:00
                      </p>
                    </div>
                  </div> */}
                </Col>
                <Col className="ml-auto mr-auto" md="5">
                  <Card className="card-contact card-raised">
                    <Form id="contact-form" action="https://formspree.io/f/meqpkbnd" method="POST" role="form">
                      <CardHeader className="text-center">
                        <CardTitle tag="h4">Contact Us</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col className="pr-2" md="6">
                            <label>First name</label>
                            <InputGroup
                              className={
                                firstNameFocus ? "input-group-focus" : ""
                              }
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="now-ui-icons users_circle-08"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                autoComplete="name"
                                placeholder="First Name..."
                                type="text"
                                name="name"
                                onFocus={() => setFirstNameFocus(true)}
                                onBlur={() => setFirstNameFocus(false)}
                                required
                              ></Input>
                            </InputGroup>
                          </Col>
                          <Col className="pl-2" md="6">
                            <FormGroup>
                              <label>Subject</label>
                              <InputGroup
                                className={
                                  lastNameFocus ? "input-group-focus" : ""
                                }
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="now-ui-icons text_caps-small"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  autoComplete="name"
                                  placeholder="Subject..."
                                  name="subject"
                                  type="text"
                                  onFocus={() => setLastNameFocus(true)}
                                  onBlur={() => setLastNameFocus(false)}
                                  required
                                ></Input>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <label>Email address</label>
                          <InputGroup
                            className={emailFocus ? "input-group-focus" : ""}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="now-ui-icons ui-1_email-85"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              autoComplete="email"
                              placeholder="Email Here..."
                              type="email"
                              name="_replyto"
                              onFocus={() => setEmailFocus(true)}
                              onBlur={() => setEmailFocus(false)}
                              required
                            ></Input>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <label>Your message</label>
                          <Input
                            id="message"
                            name="message"
                            rows="6"
                            type="textarea"
                            required
                          ></Input>
                        </FormGroup>
                        <Row>
                          <Col md="6">
                            {/* <FormGroup check>
                              <Label check>
                                <Input type="checkbox"></Input>
                                <span className="form-check-sign"></span>
                                I'm not a robot
                              </Label>
                            </FormGroup> */}
                          </Col>
                          <Col md="6">
                            <Button
                              className="btn-round pull-right"
                              color="primary"
                              type="submit"
                            >
                              Send Message
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Form>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Services;
