import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/react-demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages
import AboutUs from "views/examples/AboutUs.js";
import ContactUs from "views/examples/ContactUs.js";
import HomePage from "views/HomePage.js";
import WorkPage from "views/WorkPage.js";
import Services from "views/examples/Services";
import CookieConsent from "react-cookie-consent";
// import BlogPost from "views/examples/BlogPost.js";
// import BlogPosts from "views/examples/BlogPosts.js";
// import Ecommerce from "views/examples/Ecommerce.js";
// import AllComponents from "views/AllComponents.js";
// import LandingPage from "views/examples/LandingPage.js";
// import LoginPage from "views/examples/LoginPage.js";
// import NucleoIcons from "views/NucleoIcons.js";
// import Presentation from "views/Presentation.js";
// import Pricing from "views/examples/Pricing.js";
// import ProductPage from "views/examples/ProductPage.js";
// import ProfilePage from "views/examples/ProfilePage.js";
// import Sections from "views/Sections.js";
// import SignupPage from "views/examples/SignupPage.js";
// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/about-us" render={(props) => <AboutUs {...props} />} />
      <Route path="/contact-us" render={(props) => <ContactUs {...props} />} />
      <Route path="/services" render={(props) => <Services {...props} />} />
      <Route path="/our-work" render={(props) => <WorkPage {...props} />} />
      <Route path="/" render={(props) => <HomePage {...props} />} />
      <Redirect to="/" />
      {/* <Route path="/blog-post" render={(props) => <BlogPost {...props} />} /> */}
      {/* <Route path="/blog-posts" render={(props) => <BlogPosts {...props} />} /> */}
      {/* <Route path="/e-commerce" render={(props) => <Ecommerce {...props} />} /> */}
      {/* <Route path="/Index" render={(props) => <AllComponents {...props} />} /> */}
      {/* <Route
        path="/landing-page"
        render={(props) => <LandingPage {...props} />}
      />
      <Route path="/login-page" render={(props) => <LoginPage {...props} />} />
      <Route
        path="/nucleo-icons"
        render={(props) => <NucleoIcons {...props} />}
      />
      <Route
        path="/presentation"
        render={(props) => <Presentation {...props} />}
      /> */}
      {/* <Route path="/pricing" render={(props) => <Pricing {...props} />} /> */}
      {/* <Route
        path="/product-page"
        render={(props) => <ProductPage {...props} />}
      />
      <Route
        path  ="/profile-page"
        render={(props) => <ProfilePage {...props} />}
      />
      <Route path="/sections" render={(props) => <Sections {...props} />} />
      <Route path="/sign-up" render={(props) => <SignupPage {...props} />} /> */}
    </Switch>
    <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="cookieBar"
        style={{ background: "#fff", color: "#000" }}
        buttonStyle={{ background: "#c13e3c", color: "#fff", fontSize: "13px" }}
        expires={365}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
  </BrowserRouter>,
  document.getElementById("root")
);
