/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components

function Pricing() {
  return (
    <>
      <div className="section section-pricing" id="sectionBuy">
        <Container>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h2 className="title">Our Clients</h2>
          </Col>
          <Col className="text-center ml-auto mr-auto mt-5" md="8">
            <h4 className="description">Our experience is the most valuable resource we offer. 
              We have forged robust and enduring partnerships with companies and charities across various industries in the UK, USA, UAE, British Virgin Islands, and Australia. These relationships have enabled us to gain valuable insights and foresight, allowing us to navigate the path ahead with clarity. By recognizing potential obstacles, anticipating challenges, and formulating precise strategies, we are dedicated to paving the way for digital success.</h4>
          </Col>
          <div className="our-clients">
            <Container>
              <Row style={{display:'flex', alignItems:'center', alignContent: 'center'}}>
                <Col md="2" style={{display:'flex', justifyContent:'center', paddingBottom:'20px', paddingTop:'20px'}}>
                  <img
                    alt="..."
                    src={
                      require("assets/img/partners/ob-logo.svg")
                        .default
                    }
                    id="oblogo"
                  ></img>
                  <UncontrolledTooltip target="oblogo">
                    Onebillion Children <br />
                    UK
                  </UncontrolledTooltip>
                </Col>
                <Col md="2" style={{display:'flex', justifyContent:'center', paddingBottom:'20px', paddingTop:'20px'}}>
                  <img
                    alt="..."
                    src={
                      require("assets/img/partners/yif-logo.svg")
                        .default
                    }
                    id="yiflogo"
                  ></img>
                  <UncontrolledTooltip target="yiflogo">
                    Yusuf Islam Foundation <br />
                    UK
                  </UncontrolledTooltip>
                </Col>
                <Col md="2" style={{display:'flex', justifyContent:'center', paddingBottom:'20px', paddingTop:'20px'}}>
                  <img
                    alt="..."
                    src={
                      require("assets/img/partners/png-logo.png")
                        .default
                    }
                    id="pngLogo"
                  ></img>
                  <UncontrolledTooltip target="pngLogo">
                    Pangolin <br />
                    Australia
                  </UncontrolledTooltip>
                </Col>
                <Col md="2" style={{display:'flex', justifyContent:'center', paddingBottom:'20px', paddingTop:'20px'}}>
                  <img
                    alt="..."
                    src={
                      require("assets/img/partners/ght-logo.png")
                        .default
                    }
                    id="ghtLogo"
                  ></img>
                  <UncontrolledTooltip target="ghtLogo">
                    Global Hash Tag <br />
                    USA
                  </UncontrolledTooltip>
                </Col>
                <Col style={{display:'flex', justifyContent:'center', paddingBottom:'20px', paddingTop:'20px'}} md="2">
                  <img
                    alt="..."
                    src={
                      require("assets/img/partners/ctst-logo.png")
                        .default
                    }
                    id="ctst"
                  ></img>
                  <UncontrolledTooltip target="ctst">
                    Yusuf/Cat Stevens <br />
                    UAE/UK
                  </UncontrolledTooltip>
                </Col>
                <Col md="2" style={{display:'flex', justifyContent:'center', paddingBottom:'20px', paddingTop:'20px'}}>
                  <img
                    alt="..."
                    src={
                      require("assets/img/partners/dca-ico.png")
                        .default
                    }
                    id="dca"
                  ></img>
                  <UncontrolledTooltip target="dca">
                    Diyanet Center Of America <br />
                    USA
                  </UncontrolledTooltip>
                </Col>
                <Col md="2" style={{display:'flex', justifyContent:'center', paddingBottom:'20px', paddingTop:'20px'}}>
                  <img
                    alt="..."
                    src={
                      require("assets/img/partners/anm-logo.png")
                        .default
                    }
                    id="anm"
                  ></img>
                  <UncontrolledTooltip target="anm">
                    Animatik Arts <br />
                    UK
                  </UncontrolledTooltip>
                </Col>
                <Col md="2" style={{display:'flex', justifyContent:'center', paddingBottom:'20px', paddingTop:'20px'}}>
                  <img
                    alt="..."
                    src={
                      require("assets/img/partners/happy-earth-solutions-logo-new.png")
                        .default
                    }
                    id="hpes"
                  ></img>
                  <UncontrolledTooltip target="hpes">
                    Happy Earth Solutions <br />
                    USA
                  </UncontrolledTooltip>
                </Col>
                <Col md="2" style={{display:'flex', justifyContent:'center', paddingBottom:'20px', paddingTop:'20px'}}>
                  <img
                    alt="..."
                    src={
                      require("assets/img/partners/st-logo.png")
                        .default
                    }
                    id="stlogo"
                  ></img>
                  <UncontrolledTooltip target="stlogo">
                    Shura Trust <br />
                    UK
                  </UncontrolledTooltip>
                </Col>
                <Col md="2" style={{display:'flex', justifyContent:'center', paddingBottom:'20px', paddingTop:'20px'}}>
                  <img
                    alt="..."
                    src={
                      require("assets/img/partners/an-ps.svg")
                        .default
                    }
                    id="anp"
                  ></img>
                  <UncontrolledTooltip target="anp">
                    AN'Psikoloji <br />
                    Turkey
                  </UncontrolledTooltip>
                </Col>
              </Row>
            </Container>
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h4 className="title">Contact us today to discuss any of the services we offer and kick-start your next digital marketing project with us!</h4>
            </Col>
            <Col className="text-center ml-auto mr-auto" md="8">
              <Button
                color="primary"
                // target="_blank"
                to="/contact-us"
                tag={Link}
              >
                Contact Us
              </Button>
            </Col>
            <Col className="text-center ml-auto mr-auto mt-5" md="8">
              {/* <h3>Thank you for supporting us!</h3> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Pricing;
