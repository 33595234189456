import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ScrollTransparentShortNavbar from "components/Navbars/ScrollTransparentShortNavbar.js";
// import AboutUsHeader from "components/Headers/AboutUsHeader.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import Footer from "components/Footers/Footer.js";

function AboutUs() {
  const [specialitySelect, setSpecialitySelect] = React.useState(null);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [subjectFocus, setSubjectFocus] = React.useState(false);
  React.useEffect(() => {
    document.addEventListener('contextmenu', event => event.preventDefault());
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ScrollTransparentShortNavbar type="about-us" />
      <div className="wrapper">
        <LandingPageHeader />
        
        <div className="section">
          {/* <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto" md="8">
                    <h2 className="title">Simpler. Smarter. Faster.</h2>
                    <h4 className="description">
                    We are a web & app development company that turns your ideas into a new driving force of your business.
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <div className="info info-hover">
                      <div className="icon icon-success icon-circle">
                        <i className="now-ui-icons objects_globe"></i>
                      </div>
                      <h4 className="info-title">Web development</h4>
                      <p className="description">
                        We develop websites and web applications with regard to your needs in the highest quality and a rapid way. We also integrate other solutions into your web application need-based. These are artificial intelligence, image and sound processing ...
                      </p>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="info info-hover">
                      <div className="icon icon-info icon-circle">
                        <i className="now-ui-icons tech_mobile"></i>
                      </div>
                      <h4 className="info-title">App Development</h4>
                      <p className="description">
                      We design and enhance your mobile applications in proportion to your necessities quickly. Furthermore, we remember the content management panel and API!
                      </p>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="info info-hover">
                      <div className="icon icon-primary icon-circle">
                        <i className="now-ui-icons education_atom"></i>
                      </div>
                      <h4 className="info-title">Audio & Image Processing</h4>
                      <p className="description">
                        We make complex structures easy. We develop the image and audio processing solutions required in your applications.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <div className="separator-line bg-info"></div>
          <div className="projects-5">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title">We have many advantages</h2>
                  <h4 className="description">
                  We offer professional services to our customers in numerous fields and use the most up-to-date technologies in our projects.
                  </h4>
                  <div className="section-space"></div>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto" md="5">
                  <Card
                    className="card-background card-background-product card-raised"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/project8.jpg").default +
                        ")",
                    }}
                  >
                    <CardBody>
                      <CardTitle tag="h2">Software Development Consultancy</CardTitle>
                      <p className="card-description">
                        Insight to help you create, connect, and convert.
                        Understand Your Audience's Interests, Influence,
                        Interactions, and Intent. Discover emerging topics and
                        influencers to reach new audiences.
                      </p>
                      <Badge className="badge-neutral">Development</Badge>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="mr-auto" md="5">
                  <div className="info info-horizontal">
                    <div className="icon icon-danger">
                      <i className="now-ui-icons ui-2_chat-round"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">
                        Web applications
                      </h4>
                      <p className="description">
                        Gain access to the demographics, psychographics, and
                        location of unique people who talk about your brand.
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-danger">
                      <i className="now-ui-icons business_chart-pie-36"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Mobile Application</h4>
                      <p className="description">
                        Unify data from Facebook, Instagram, Twitter, LinkedIn,
                        and Youtube to gain rich insights from easy-to-use
                        reports.
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-danger">
                      <i className="now-ui-icons design-2_ruler-pencil"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Server Management</h4>
                      <p className="description">
                        Track actions taken on your website that originated from
                        social, and understand the impact on your bottom line.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <hr></hr>
              <Row>
                <Col className="ml-auto mt-5" md="5">
                  <div className="info info-horizontal">
                    <div className="icon icon-warning">
                      <i className="now-ui-icons users_single-02"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Artificial Intelligence</h4>
                      <p className="description">
                        Whether it’s for work or even the next family vacation,
                        Trello helps your team.
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-warning">
                      <i className="now-ui-icons business_chart-bar-32"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Audio&Image Processing</h4>
                      <p className="description">
                        Integrate the apps your team already uses directly into
                        your workflow.
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-warning">
                      <i className="now-ui-icons arrows-1_refresh-69"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Voice Activity Detection&Classification</h4>
                      <p className="description">
                        No matter where you are, Trello stays in sync across all
                        of your devices.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="mr-auto" md="5">
                  <Card
                    className="card-background card-background-product card-raised"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/project9.jpg").default +
                        ")",
                    }}
                  >
                    <CardBody>
                      <CardTitle tag="h2">Niche Services</CardTitle>
                      <p className="card-description text-white">
                        Trello’s boards, lists, and cards enable you to organize
                        and prioritize your projects in a fun, flexible and
                        rewarding way.
                      </p>
                      <Badge className="badge-neutral">Engineering</Badge>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div> */}
          <div className="section-about-us">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title">Who we are?</h2>
                  <h3 className="description">
                  We are one of the leading software consultancy firms helping innovative 
                  businesses & charities create excellent digital services.
                  </h3>
                </Col>
              </Row>
              <div className="separator separator-info"></div>
              <div className="section-story-overview">
                <Row>
                  <Col md="6">
                    <div
                      className="image-container image-left"
                      style={{
                        backgroundImage:
                          "url(" + require("assets/img/bg38.jpg").default + ")",
                      }}
                    >
                      <p className="blockquote blockquote-info">
                        "With 7 years of experience in digital transformation, we help companies and charities create easy-to-use, beautiful and robust digital experiences for a new generation of customers." <br></br>
                      </p>
                    </div>
                    <div
                      className="image-container image-left-bottom"
                      style={{
                        backgroundImage:
                          "url(" + require("assets/img/bg24.jpg").default + ")",
                      }}
                    ></div>
                  </Col>
                  <Col md="5">
                    <div
                      className="image-container image-right"
                      style={{
                        backgroundImage:
                          "url(" + require("assets/img/bg39.jpg").default + ")",
                      }}
                    ></div>
                    <h3>
                      Software Development Consultancy helping businesses grow online
                    </h3>
                    <p>
                      Redsceptre IT Solutions was started in 2020. Seeing a need to deliver responsive, dynamic applications and websites for SMEs and charities – the concept of Redsceptre development was created.
                    </p>
                    <p>
                      Since then, we have worked with a number of businesses and charities to create dynamic and posthaste websites and creative applications. We are invested in achieving the best results possible for all of our clients and our decision making is based on facts and driven by results.
                    </p>
                    <p>
                      When working with a client we spend time getting to know the business and its goals. As a creative digital software consultant, we pride ourselves on delivering original and imaginative concepts for both usability and interactivity.
                    </p>
                    <p>
                      We work with a variety of businesses and charities in different sectors to develop websites and applications with a difference. Our ambitious team of developers enjoy the challenge of helping companies make the most of the web.
                    </p>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          <div className="about-team team-4">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title">We are nerd rockstars</h2>
                  <h4 className="description">
                  Everyone says it, but in our case it’s true: our team is the secret to our success.
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto" lg="7" xl="6">
                  <Card className="card-profile card-plain">
                    <Row>
                      <Col md="5">
                        <div className="card-image">
                            <img
                              style={{pointerEvents: 'none'}}
                              alt="..."
                              className="img img-raised rounded"
                              src={require("assets/img/bilal.jpeg").default}
                            ></img>
                        </div>
                      </Col>
                      <Col md="7">
                        <CardBody>
                          <CardTitle tag="h4">Bilal Corbacioglu</CardTitle>
                          <h6 className="category">Director</h6>
                          <p className="card-description">
                            Dream until it's your reality......
                          </p>
                          <CardFooter>
                            <Button
                              className="btn-icon btn-neutral"
                              color="linkedin"
                              href="https://linkedin.com/bilalcorbacioglu"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-linkedin"></i>
                            </Button>
                            <Button
                              className="btn-icon btn-neutral"
                              color="github"
                              href="https://github.com/bilalcorbacioglu"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-github"></i>
                            </Button>
                          </CardFooter>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h3 className="title">Outside Team</h3>
                  <h5 className="description">
                    We collaborate with freelancers from time to time on projects based.
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto" lg="7" xl="6">
                  <Card className="card-profile card-plain">
                    <Row>
                      <Col md="5">
                        <div className="card-image">
                            <img
                              alt="..."
                              className="img img-raised rounded"
                              src={require("assets/img/profile.png").default}
                            ></img>
                        </div>
                      </Col>
                      <Col md="7">
                        <CardBody>
                          <CardTitle tag="h4">Nuray Yuksel</CardTitle>
                          <h6 className="category">Freelance Designer</h6>
                          <p className="card-description">
                            Love cures people - both the ones who give it and
                            the ones who receive it...
                          </p>
                          <CardFooter>
                            <Button
                              className="btn-icon btn-neutral"
                              color="behance"
                              href="https://www.behance.net/valaderes"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-behance"></i>
                            </Button>
                          </CardFooter>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col className="ml-auto mr-auto" lg="7" xl="6">
                  <Card className="card-profile card-plain">
                    <Row>
                      <Col md="5">
                        <div className="card-image">
                            <img
                              alt="..."
                              className="img img-raised rounded"
                              src={require("assets/img/profile.png").default}
                            ></img>
                        </div>
                      </Col>
                      <Col md="7">
                        <CardBody>
                          <CardTitle tag="h4">Mustafa Dal</CardTitle>
                          <h6 className="category">Freelance Frontend Developer</h6>
                          <p className="card-description">
                            Keep your face always toward the sunshine - and
                            shadows will fall behind you...
                          </p>
                          <CardFooter>
                          </CardFooter>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="about-office">
            <Container>
              <Row className="text-center">
                <Col className="ml-auto mr-auto" md="8">
                  <h2 className="title">Our spaces are our second home</h2>
                  <h4 className="description" style={{marginBottom:3}}>
                    Here are some pictures from our coworking spaces. 
                    You can see the place looks like a palace and is fully equipped with 
                    everything you need to get the job done.
                  </h4>
                  <h6 className="description" style={{
                    fontSize: 6,
                    fontWeight: 100
                    }}>&#42;We use The Boutique Workplaces in London.</h6>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/office-1.webp").default}
                  ></img>
                </Col>
                <Col md="4">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/office-2.webp").default}
                  ></img>
                </Col>
                <Col md="4">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/office-3.webp").default}
                  ></img>
                </Col>
                <Col md="6">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/office-5.webp").default}
                  ></img>
                </Col>
                <Col md="6">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/img/office-4.webp").default}
                  ></img>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="section-contact-us text-center">
          <Form id="contactForm" action="https://formspree.io/f/meqpkbnd" method="POST">
            <Container>
            <Row className="text-center">
            <Col className="ml-auto mr-auto" md="8">

              <h2 className="title">Want to work with us?</h2>
              <p className="description">Divide details about your product or agency work into parts.
                    Write a few lines about each one and contact us about any
                    further collaboration. We will get back to you in a couple
                    of days.</p>
                    </Col>
                    </Row>
              <Row>
                <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                  <InputGroup
                    className={
                      firstFocus ? "input-lg input-group-focus" : "input-lg"
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons users_circle-08"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Your Name..."
                      type="text"
                      id="name"
                      name="name"
                      onFocus={() => setFirstFocus(true)}
                      onBlur={() => setFirstFocus(false)}
                      required
                    ></Input>
                  </InputGroup>
                  <InputGroup
                    className={
                      emailFocus ? "input-lg input-group-focus" : "input-lg"
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons ui-1_email-85"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email Here..."
                      type="email"
                      name="_replyto"
                      id="email"
                      onFocus={() => setEmailFocus(true)}
                      onBlur={() => setEmailFocus(false)}
                      required
                    ></Input>
                  </InputGroup>
                  <InputGroup
                    className={
                      subjectFocus ? "input-lg input-group-focus" : "input-lg"
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons text_caps-small"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Subject..."
                      type="text"
                      id="subject"
                      name="_subject"
                      onFocus={() => setSubjectFocus(true)}
                      onBlur={() => setSubjectFocus(false)}
                      required
                    ></Input>
                  </InputGroup>
                  <div className="textarea-container">
                    <Input
                      cols="80"
                      name="message"
                      id="message"
                      placeholder="Type a message..."
                      rows="4"
                      type="textarea"
                      required
                    ></Input>
                  </div>
                  <div className="send-button">
                    <Button
                      block
                      className="btn-round"
                      color="primary"
                      size="lg"
                    >
                      Send Message
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Form>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default AboutUs;
