/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Button, Row } from "reactstrap";

// core components

function Footer() {
  return (
    <>
      <footer className="footer footer-white">
        <Container style={{ paddingBottom: "2em", paddingTop: "2em" }}>
          <img src={require("assets/img/redsceptre-icon.png").default} width={80} height={80} />
        </Container>
        <Container style={{ marginBottom: "1em" }}>
          <ul className="social-buttons pull-center">
            <li>
              <Button
                className="btn-icon btn-neutral"
                color="linkedin"
                href="https://www.linkedin.com/company/redsceptre/"
                target="_blank"
              >
                <i className="fab fa-linkedin"></i>
              </Button>
            </li>
            <li>
              <Button
                className="btn-icon btn-neutral"
                color="github"
                href="https://github.com/redsceptre"
                target="_blank"
              >
                <i className="fab fa-github"></i>
              </Button>
            </li>
          </ul>
        </Container>
        <Container style={{ fontFamily: "AvantGardeGothicRegular", paddingBottom: "6em" }}>
          <Row className="pull-center" style={{ paddingBottom: 2 }}>
            <a href="mailto:info@redsceptre.com" target="_blank">info@redsceptre.com</a>
          </Row>
          <br />
          <Row className="pull-center" style={{ paddingBottom: 2 }}>
            7 Bell Yard, London, WC2A 2JR
          </Row>
          <br />
          <Row className="pull-center" style={{ paddingBottom: 2 }}>
            © {new Date().getFullYear()}.
            Redsceptre IT Solutions LTD is a registered company in England and Wales &nbsp;
            <a href="https://find-and-update.company-information.service.gov.uk/company/12422542" target="_blank">№ 12422542</a>
          </Row>
          <br/>
          <Row className="pull-center" style={{ paddingBottom: 2 }}>
            We are in <a href="https://redsceptre.eth.limo" target="_blank">Web3!</a>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
